<script>
import { ref, onMounted, inject } from '@vue/composition-api'

export default {
  setup() {
    const options = ref([])
    const selectedOption = ref(null)
    const file = ref(null)

    const filtersService = inject('filtersservice')

    const tabs = ref([
      { id: 'Player', label: 'jogadores' },
      { id: 'staff', label: 'staff' },
      { id: 'AssociateMember', label: 'Sócios' },
    ])
    const selectedTab = ref('jogadores')

    const fetchOptions = async () => {
      try {
        const response = await filtersService.get()
        options.value = response.ClubLevels
      } catch (error) {
        console.error('Error fetching options:', error)
      }
    }

    const uploadFile = () => {
      const selectedTabValue = tabs.value[selectedTab.value]

      let url = `${process.env.VUE_APP_BASE_URL}/api/Importer?uploadType=${selectedTabValue.id}`
      if (selectedTabValue.id === 'Player') {
        if (!selectedOption.value) {
          alert('You should select an Option') // eslint-disable-line no-alert

          return
        }
        url += `&clubLevel=${selectedOption.value}`
      }

      const formData = new FormData()
      formData.append('file', file.value)
      formData.append('option', selectedOption.value)

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then(() => {
          alert('Importação finalizada com sucesso.') // eslint-disable-line no-alert
        })
        .catch(() => {
          alert('Ocorreu um erro na importação. Por favor carregue as entidades em falta apenas.') // eslint-disable-line no-alert
        })
    }

    onMounted(() => {
      fetchOptions()
    })

    return {
      options,
      selectedOption,
      file,
      uploadFile,
      selectedTab,
      tabs,
    }
  },
}
</script>

<template>
  <v-tabs v-model="selectedTab">
    <v-tab
      v-for="tab in tabs"
      :key="tab.id"
    >
      {{ tab.label }}
    </v-tab>
    <v-tab-item
      v-for="tab in tabs"
      :key="tab.id"
    >
      <v-card>
        <v-container>
          <v-row>
            <v-col
              v-if="tab.id === 'Player'"
              cols="12"
              sm="6"
            >
              <v-select
                v-model="selectedOption"
                :items="options"
                label="Escolhe um escalão"
                outlined
                dense
                clearable
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="d-flex justify-end"
            >
              <v-file-input
                v-model="file"
                label="Upload do ficheiro"
                outlined
                dense
                accept=".csv, .xlsx, .xls"
              />
              <v-btn
                color="primary"
                :disabled="!file"
                @click="uploadFile"
              >
                Upload
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>
